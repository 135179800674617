


























import { Action, Getter, Mutation } from 'vuex-class';
import { Component, Vue, Watch } from 'vue-property-decorator';
import * as types from '@/store/types';

const namespace: string = 'flow';

@Component
export default class SortFlows extends Vue {
  @Getter(types.GET_FLOW_NAMES, { namespace }) getFlowNames: any;

  @Mutation(types.MUTATE_FLOW_SORT_BY, { namespace }) mutateFlowSort: any;

  @Mutation(types.MUTATE_SORT_FLOWS, { namespace }) sortFlows: any;

  private sortOptions: any[] = [
    {
      value: 'name',
      props: 'name',
      text: 'sort_by_name',
    },
    {
      value: 'state',
      props: ['running', 'name'],
      text: 'sort_by_state',
    },
  ];

  // initial sort: get stored value else default to name
  private selectedSort: any = localStorage.getItem('sortFlowsBy') || 'name';

  getPropsForSelection() {
    const selected = this.sortOptions.find(
      (option) => option.value === this.selectedSort,
    );
    return selected.props;
  }

  created() {
    this.mutateFlowSort(this.getPropsForSelection());
  }

  changeSorting() {
    localStorage.setItem('sortFlowsBy', this.selectedSort);
    this.mutateFlowSort(this.getPropsForSelection());
    this.sortFlows();
  }
}
