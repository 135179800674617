

















































import { Action, Getter } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import * as types from '@/store/types';

const namespace: string = 'flow';
@Component
export default class ImportFlow extends Vue {
  @Action(types.IMPORT_FLOW, { namespace }) importFlow: any;

  file: any = [];

  loading: boolean = false;

  async submit() {
    try {
      this.loading = true;
      const form = new FormData();
      if (this.file.name.length > 0) {
        form.append('flow', this.file);
      }
      this.$emit('submited');

      const { workspaceId } = this.$route.params;
      await this.importFlow({ workspaceId, file: form });

      this.file = [];
      this.loading = false;
    } catch (error) {
      this.$emit('submited');
      this.loading = false;
    }
  }

  close() {
    this.$emit('close');
  }
}
