









































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';

@Component
export default class ParameterErrors extends Vue {
    @Prop() dialog!: boolean;

    @Prop() items!: string[];

    closeDialog() {
      this.$emit('close-dialog');
    }
}
