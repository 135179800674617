














































import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import * as types from '@/store/types';
import Toolbar from '@/components/shared/Toolbar.vue';
import SortFlows from '@/components/flow/SortFlows.vue';

const namespaceFlow: string = 'flow';

@Component({
  components: {
    Toolbar,
    SortFlows,
  },
})
export default class ToolbarFlow extends Vue {
  @Action(types.SEARCH_FLOW, { namespace: namespaceFlow }) searchFlow: any;

  @Prop({ default: false }) isFlowReadOnly!: boolean;

  @Prop({ default: false }) title!: string;

  private name: string = '';

  @Watch('name')
  onSearchChanged() {
    this.searchFlow(this.name);
  }
}
