































































































































import { Action } from 'vuex-class';
import { Component, Prop, Vue } from 'vue-property-decorator';
import * as types from '@/store/types';
import CopyFlow from '@/components/flow/CopyFlow.vue';
import DeleteFlow from '@/components/flow/DeleteFlow.vue';
import openFlowMetrics from '@/scripts/openGrafana';
import PopupInfo from '@/components/flow/PopupInfo.vue';
import ExportFlow from '@/components/flow/ExportFlow.vue';

const namespace: string = 'flow';

@Component({
  components: {
    CopyFlow,
    DeleteFlow,
    PopupInfo,
    ExportFlow,
  },
})
export default class FlowMenu extends Vue {
  @Prop() flowName!: string;

  @Prop() flowID!: string;

  @Prop() index!: number;

  @Prop() previewimage!: string;

  @Prop() flowDescription!: string;

  @Prop() flowOwner!: string;

  @Prop({ default: false }) isFlowReadOnly!: boolean;

  private deleteDialog: boolean = false;

  private copyDialog: boolean = false;

  private popupInfoDialog: boolean = false;

  private maxChars = 10;

  @Action(types.SET_SELECTED_FLOW, { namespace }) selectFlow: any;

  private routeToFlows() {
    this.$router.push({ name: 'flow', params: { flowname: this.flowID } });
  }

  private truncated(str: string) {
    return str.length > this.maxChars ? str.substring(0, this.maxChars).concat('...') : str;
  }

  private openMetricsPage() {
    openFlowMetrics(this.flowName);
  }

  submitExportFlow() {
    const { exportFlow }: any = this.$refs;
    exportFlow.submit();
  }
}
