




























































































































































import { Action, Getter, Mutation } from 'vuex-class';
import { Component, Vue, Watch } from 'vue-property-decorator';
import * as types from '@/store/types';
import NewFlow from '@/components/flow/newFlow/Index.vue';
import FlowMenu from '@/components/flow/FlowMenu.vue';
import { Flow } from '@/store/flow/models';
import Toolbar from '@/components/flow/ToolbarFlow.vue';
import ParameterErrors from '@/components/editor/ParameterErrors.vue';
import { ErrorLevel } from '@/store/errors/models';
import { FlowOwner } from '@/store/user/models';
import { validateBricksParameters } from '@/scripts/editor/helper';
import { Workspace } from '@/store/workspace/models';
import Avatar from '@/components/user/Avatar.vue';

const namespace: string = 'flow';
const namespaceError: string = 'error';
const namespaceUser: string = 'user';
const workspace: string = 'workspace';

@Component({
  components: {
    NewFlow,
    FlowMenu,
    Toolbar,
    ParameterErrors,
    Avatar,
  },
})
export default class FlowList extends Vue {
  // Actions
  @Action(types.FETCH_FLOWS, { namespace }) fetchFlows: any;

  @Action(types.FETCH_FLOW, { namespace }) fetchFlow: any;

  @Action(types.SET_SELECTED_FLOW, { namespace }) selectFlow: any;

  @Action(types.CHANGE_RUN_STATE, { namespace }) changeRunState: any;

  @Action(types.SET_ERROR, { namespace: namespaceError }) setError: any;

  // Getters
  @Getter(types.GET_FLOW_NAMES, { namespace }) getFlowNames: any;

  @Getter(types.GET_FLOWS, { namespace }) getFlows: any;

  @Getter(types.GET_FLOW, { namespace }) getFlow: any;

  @Getter(types.GET_LOADING, { namespace }) getLoading: any;

  @Getter(types.GET_FLOW_OWNERS, { namespace: namespaceUser }) getFlowOwners: any;

  @Getter(types.GET_USER, { namespace: namespaceUser }) getUser: any;

  @Getter(types.GET_USER_WORKSPACES, { namespace: workspace }) getWorkspaces: any;

  // Mutations
  @Mutation(types.MUTATE_SORT_FLOWS, { namespace }) sortFlows: any;

  // Data
  private observer: any;

  private isFlowAdded = false;

  private loading = false;

  private dialogParameterError: boolean = false;

  private invalidBrickParams: string[] = [];

  private workspace: Workspace = {
    id: '',
    name: '',
    owner: { id: '', email: '', username: '' },
    members: [],
    flows: [],
  };

  private maxChars = 20;

  private renderComponent: boolean = true;

  // Watchers
  @Watch('$route')
  async onRouter(to: any, from: any) {
    if (to.path !== from.path) {
      await this.init();
    }
  }

  @Watch('getLoading')
  onLoadingCompleted() {
    if (!this.getLoading) {
      this.$nextTick(() => this.registerFlowForAnimation());
    }
  }

  // Vue Life Cycle Hooks
  async mounted() {
    await this.init();
  }

  // Methods

  /**
   * Prepares the container where the flows are rendered
   * to show a pulse animation when a new flow is added
   * to the list
   */
  registerFlowForAnimation() {
    const self = this;

    // Select the node that will be observed for mutations
    const targetNode = this.$refs.flowContainer;

    // Options for the observer (which mutations to observe)
    const config = { attributes: true, childList: true, subtree: false };

    // Callback function to execute when mutations are observed
    const callback = (mutationsList: any, observer: any) => {
      // Use traditional 'for loops' for IE 11
      for (const mutation of mutationsList) {
        if (
          self.isFlowAdded
          && mutation.type === 'childList'
          && mutation.addedNodes.length > 0
          && mutation.addedNodes[0].tagName === 'SPAN'
        ) {
          const name = document.createAttribute('class');
          name.value = 'highlight';
          mutation.addedNodes[0].attributes.setNamedItem(name);
        }
      }

      // so that when the user searches for a flow, it doesnt animate
      self.isFlowAdded = false;
    };

    // Create an observer instance linked to the callback function
    this.observer = new MutationObserver(callback);

    // Start observing the target node for configured mutations
    if (targetNode) {
      this.observer.observe(targetNode as HTMLElement, config);
    }
  }

  /**
   * Initializes the component by fetching and
   * setting necessary stuff
   */
  async init() {
    const { workspaceId } = this.$route.params;
    if (!workspaceId) {
      return;
    }
    await this.fetchFlows(workspaceId);

    if (this.getFlowNames) {
      this.sortFlows();
    }

    this.setWorkspace(workspaceId);
  }

  /**
   * Decides how much of a string should be truncated
   * based on @member maxChars
   */
  truncated(str: string) {
    return str.length > this.maxChars ? str.substring(0, this.maxChars).concat('...') : str;
  }

  /**
   * Sets the context of the workspace
   * the user is inside
   */
  setWorkspace(workspaceId: string) {
    const workspaces = this.getWorkspaces;
    const findWorkspace = workspaces.find((w: any) => w.id === workspaceId);
    if (findWorkspace) {
      this.workspace = findWorkspace;
    }
  }

  private getFlowOwnerUsername(flowOwnerId: string) {
    const user = this.getFlowOwners.find((flowOwner: FlowOwner) => flowOwner.id === flowOwnerId);
    if (user) {
      return user.username;
    }
    return '';
  }

  getFlowOwnerEmail(flowOwnerId: string): string {
    if (this.getWorkspaces) {
      const { workspaceId } = this.$route.params;
      if (!workspaceId) {
        return '';
      }
      const currentWorkspace = this.getWorkspaces.find((w: Workspace) => w.id === workspaceId);

      if (currentWorkspace) {
        // if there is not members, just return owner email
        if (currentWorkspace.members.length < 1) {
          return currentWorkspace.owner.email;
        }
        const members = [...currentWorkspace.members, currentWorkspace.owner];
        const owner = members.find((m) => m.id === flowOwnerId);
        if (owner) {
          return owner.email;
        }
      }
    }
    return '';
  }

  forceRender() {
    // remove cards from DOM
    this.renderComponent = false;

    this.$nextTick(() => {
      // Add cards component to the Dom
      this.renderComponent = true;
    });
  }

  routeToFlows(cflow: Flow) {
    if (!cflow.id) {
      // TODO: translate the error message
      this.setError({
        error: 'Flow does not have any ID',
        level: ErrorLevel.ERROR,
      });
    }
    this.selectFlow(cflow).then(() => this.$router.push({
      name: 'flow',
      params: { workspaceId: this.workspace.id, flowId: cflow.id },
    }));
  }

  async changeFlowState(cflow: Flow) {
    this.loading = true;

    await this.fetchFlow({ id: cflow.id, shouldLoad: false });

    if (!cflow.running) {
      // Validate the requires brick parameters before save
      if (this.getFlow && this.getFlow.config && this.getFlow.config.bricks) {
        const { bricks } = this.getFlow.config;

        const invalidProperties = validateBricksParameters(bricks);

        if (invalidProperties && invalidProperties.length > 0) {
          this.dialogParameterError = true;
          this.invalidBrickParams = invalidProperties;

          this.loading = false;

          return;
        }
      }
    }

    await this.changeRunState({ id: cflow.id, running: !cflow.running });

    this.loading = false;
  }
}
