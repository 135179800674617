




import { Component, Vue, Prop } from 'vue-property-decorator';
import * as storeTypes from '@/store/types';
import { Action } from 'vuex-class';

const flowNamespace: string = 'flow';

@Component
export default class ExportFlow extends Vue {
  @Prop() flowName!: string;

  @Prop() flowId!: string;

  // Actions
  @Action(storeTypes.EXPORT_FLOW, { namespace: flowNamespace }) exportFlow: any;

  // Submit should called from parent
  async submit() {
    if (this.flowId) {
      const response = await this.exportFlow({ flowId: this.flowId });
      this.downloadFile(response);
    }
  }

  downloadFile(text: any) {
    if (!text) return;

    const json = JSON.stringify(text);
    const hiddenElement = document.createElement('a');
    hiddenElement.target = '_blank';
    hiddenElement.href = `data:text/json;charset=utf-8,${encodeURI(json)}`;

    // provide the name for the JSON file to be downloaded
    hiddenElement.download = `${this.flowName}.json`;
    hiddenElement.click();
  }
}
