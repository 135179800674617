/*
 Copyright 2019-present wobe-systems GmbH

 Licensed under the Apache License, Version 2.0 (the 'License');
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

     http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an 'AS IS' BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
*/
/* eslint-disable max-len */
import Vue from 'vue';
import { MutationTree } from 'vuex';
import * as types from '@/store/types';
import { sortObjectsByProperties, insertByName } from '@/scripts/sort/sorts';
import $ from 'jquery';
import { Constant, SchemaType } from '@/scripts/shareModels/schema';
import { BUFFER_ID_PREFIX, CONSTANT_ID_PREFIX, CONSTANT_MAPPING_PREFIX } from '@/scripts/shared';
import * as nanoid from 'nanoid';
import {
  editOrDeleteSchemaFromElements,
  generateParentChainForSchema,
  removeMappingForField,
  removeMappingIfConnectedFieldsAreNotAvailable,
} from '@/components/editor/portMapping/scripts';
import { resetValidation, validate } from '@/scripts/shareModels/mapping_validation';
import {
  FlowsState,
  Flow,
  BrickInFlow,
  Connection,
  EmptyConnection,
  MappedElement,
  IBuffer,
  BufferElement,
} from './models';

export const mutations: MutationTree<FlowsState> = {
  [types.LOADING](state, loading: FlowsState['loading']) {
    state.loading = loading;
  },

  [types.MUTATE_FLOW_RECEIVED](state, payload: FlowsState['flow']) {
    state.flow.id = payload.id;
    state.flow.name = payload.name;
    state.flow.owner = payload.owner;
    state.flow.description = payload.description;
    state.flow.previewimage = payload.previewimage;
    state.flow.workspaceId = payload.workspaceId;

    if (state.flow.config) {
      // SAVE_FLOW does not return bricks and connections
      // to avoid removing them, jQuery is being used
      $.extend(state.flow.config, payload.config);
    } else {
      state.flow.config = payload.config;
    }

    if (payload.running !== undefined) {
      state.flow.running = payload.running;
    }
  },

  [types.MUTATE_FLOW_DELETED](state, id: string) {
    state.flows = state.flows.filter((flow) => flow.id !== id);
    state.selectedFlows = state.selectedFlows.filter((flow) => flow.id !== id);
  },

  [types.MUTATE_FLOWS_DELETED](state, id: string) {
    state.flows = state.flows.filter((flow) => flow.owner !== id);
    state.selectedFlows = state.selectedFlows.filter((flow) => flow.owner !== id);
  },

  [types.MUTATE_FLOWS_RECEIVED](state, payload: FlowsState['flows']) {
    state.flows = payload;

    // DEEP COPY TO SEPARATE REFERENCES
    state.selectedFlows = $.extend(true, [], state.flows);
  },

  [types.MUTATE_PORT_RECEIVED](state, payload: FlowsState['port']) {
    state.port = payload;
  },

  [types.MUTATE_FLOW_CREATED](state, payload: Flow) {
    state.flow.name = payload.name;
    state.flow.id = payload.id;
    state.flow.config = payload.config;
    state.flow.owner = payload.owner;
    state.flow.running = payload.running;
    if (!state.flows) {
      state.flows = [payload];
    } else {
      // insert sort by name
      insertByName(state.flows, payload);
    }

    if (!state.selectedFlows || state.selectedFlows.length === 0) {
      state.selectedFlows = $.extend(true, [], state.flows);
    } else {
      // insert sort by name
      insertByName(state.selectedFlows, payload);
    }
  },

  [types.MUTATE_SEARCH_FLOW](state, payload: string) {
    if (!payload) {
      state.selectedFlows = $.extend(true, [], state.flows);
    } else {
      state.selectedFlows = state.flows.filter((flow: { name: string }) => flow.name.toLowerCase().includes(payload.toLowerCase()));
    }
  },

  [types.MUTATE_CHANGE_RUN_STATE](state, payload: any) {
    state.flow.running = payload.running;

    const indexFlows = state.flows.findIndex((f) => f.id === payload.id);
    if (indexFlows !== -1) {
      state.flows[indexFlows].running = payload.running;
    }
    const indexSelectedFlows = state.selectedFlows.findIndex((f) => f.id === payload.id);
    if (indexSelectedFlows !== -1) {
      state.selectedFlows[indexSelectedFlows].running = payload.running;
    }
  },

  [types.MUTATE_FLOW_BY_NAME](state, payload: string) {
    const index = state.flows.findIndex((f) => f.name === payload);
    const flow = state.flows[index];
    if (!flow.config.bricks) {
      flow.config.bricks = [];
    }
    state.flow = flow;
    console.trace(state.flows, index, state.flows[index]);
    return true;
  },

  [types.MUTATE_BRICKS_RECEIVED](state) {
    for (const index in state.flows) {
      if (state.flows[index].config) {
        const currentBricks = state.flows[index].config.bricks || [];
        for (const cb of currentBricks) {
          const BrickFound = state.bricks.findIndex((b) => b.instanceId === cb.instanceId);
          if (BrickFound === -1) {
            state.bricks = [...state.bricks, cb];
          }
        }
      }
    }
  },

  [types.MUTATE_COPY_FLOW](state, payload: FlowsState['flow']) {
    state.flow.name = payload.name;
    state.flow.config = payload.config;
    state.flows = [...state.flows, payload];
    state.selectedFlows = [...state.selectedFlows, payload];
  },

  [types.MUTATE_FLOW_IS_DIRTY](state, payload: Flow['isDirty']) {
    state.flow.isDirty = payload;
  },

  [types.MUTATE_GET_FLOW_STATE](state, payload: Flow['running']) {
    state.flow.running = payload;
  },

  [types.MUTATE_GET_FLOWS_STATE](state, payload: Flow[]) {
    if (payload && state.flows) {
      payload.forEach((s) => {
        const flowIndex = state.flows.findIndex((f) => f.id === s.id);
        if (flowIndex !== -1) {
          state.flows[flowIndex].running = s.running;
        }

        const selectedFlowIndex = state.selectedFlows.findIndex((f) => f.id === s.id);
        if (selectedFlowIndex !== -1) {
          state.selectedFlows[selectedFlowIndex].running = s.running;
        }
      });
    }
  },

  [types.MUTATE_FLOW_SORT_BY](state, payload: FlowsState['sortFlowsBy']) {
    state.sortFlowsBy = payload;
  },

  [types.MUTATE_SORT_FLOWS](state) {
    // sortie sort sort by name
    const compareFunc = sortObjectsByProperties(state.sortFlowsBy);
    state.selectedFlows.sort(compareFunc);
    state.flows.sort(compareFunc);
  },

  [types.MUTATE_EDIT_FLOW_NAME](state, data: any) {
    if (data) {
      const index = state.flows.findIndex((f) => f.id === data.id);
      if (index !== -1) {
        state.flows[index].name = data.name;
      }

      const indexSelectedFlow = state.selectedFlows.findIndex((f) => f.id === data.id);
      if (indexSelectedFlow !== -1) {
        state.selectedFlows[index].name = data.name;
      }

      state.flow.name = data.name;
    }
  },

  [types.MUTATE_UPDATED_FLOW_IN_FLOW_LIST](state, payload: Flow) {
    if (payload) {
      const flowIndex = state.flows.findIndex((flow) => flow.id === payload.id);
      if (flowIndex !== -1) {
        state.flows.splice(flowIndex, 1, payload);
      }

      const selectedFlowIndex = state.selectedFlows.findIndex((flow) => flow.id === payload.id);
      if (selectedFlowIndex !== -1) {
        state.selectedFlows.splice(selectedFlowIndex, 1, payload);
      }
    }
  },

  [types.MUTATE_EDIT_FLOW_DESCRIPTION](state, data: any) {
    if (data) {
      const index = state.flows.findIndex((f) => f.id === data.id);
      if (index !== -1) {
        state.flows[index].description = data.description;
      }

      state.flow.description = data.description;
    }
  },

  [types.MUTATE_ADD_CONNECTION](state: FlowsState, connection: Connection) {
    if (state.connections) {
      const index = state.connections.findIndex((c) => c.id === connection.id);
      if (index === -1) {
        // new connection
        state.connections.push(connection);
      } else {
        // existing connection
        state.connections.splice(index, 1, connection);
      }
    } else {
      // first connection
      state.connections = [connection];
    }
  },

  [types.MUTATE_ADD_CONNECTIONS](state: FlowsState, connections: Connection[]) {
    state.connections = connections;
  },

  [types.MUTATE_DELETE_CONNECTION](state: FlowsState, id: string) {
    const connectionIndex = state.connections.findIndex((connection) => connection.id === id);
    if (connectionIndex >= 0) {
      state.connections.splice(connectionIndex, 1);
    }
    state.connection = EmptyConnection;
  },

  [types.MUATATE_ADD_PORT_MAPPING](
    state: FlowsState,
    { connection, mappedElement }: { connection: Connection; mappedElement: MappedElement },
  ) {
    const {
      flow: { config },
      connections,
    } = state;

    const { usedElements } = mappedElement;

    // find the connection to add the mapping to
    const connectionToModify = connections.find((c) => c.id === connection.id);

    if (usedElements[0].includes(CONSTANT_ID_PREFIX)) {
      // find the constant to save the connection ID as reference in
      const constantToUpdate = config.constants.find((c) => c.id === usedElements[0]);

      if (constantToUpdate && !constantToUpdate?.mappedConnections) {
        constantToUpdate.mappedConnections = [];
      }

      // add reference of connection in the constant
      if (connectionToModify) {
        constantToUpdate?.mappedConnections?.push(connectionToModify.id);
      }
    }

    // add the mapping to the connection
    connectionToModify?.mapping.push(mappedElement);
  },

  [types.MUTATE_ADD_BRICK_IN_FLOW](state: FlowsState, brick: BrickInFlow) {
    state.flow.config.bricks.push(brick);
  },

  [types.MUTATE_DELETE_BRICK_IN_FLOW](state: FlowsState, brickId: string) {
    const index = state.flow.config.bricks.findIndex((brick) => brick.instanceId === brickId);

    if (index !== -1) {
      state.flow.config.bricks.splice(index, 1);
    }
  },

  [types.MUATATE_DELETE_PORT_MAPPING](
    state: FlowsState,
    { connection, index }: { connection: Connection; index: number },
  ) {
    const {
      flow: { config },
      connections,
    } = state;

    // find connection whose mapping needs to be removed
    const connectionToModify = connections.find((c) => c.id === connection.id);

    if (connectionToModify) {
      const { usedElements } = connectionToModify.mapping[index];

      if (usedElements[0].includes(CONSTANT_ID_PREFIX)) {
        // find the constant to save the connection ID as reference in
        const constantToUpdate = config.constants.find((c) => c.id === usedElements[0]);

        // remove reference of connection in the constant
        constantToUpdate?.mappedConnections?.splice(
          constantToUpdate.mappedConnections.indexOf(connectionToModify.id),
          1,
        );
      }
    }

    connectionToModify?.mapping.splice(index, 1);
  },

  [types.MUTATE_ADD_CONSTANT](state: FlowsState, { constant }: { constant: Constant }) {
    const {
      flow: { config },
    } = state;

    // Initialize constants if they dont exist
    if (!config.constants) {
      config.constants = [];
    }

    // Add constants to the flow config
    config.constants.push(constant);
  },

  [types.MUTATE_EDIT_CONSTANT](
    state: FlowsState,
    {
      constant,
      shouldUpdateMappingRules = false,
    }: { constant: Constant; shouldUpdateMappingRules: boolean },
  ) {
    const {
      flow: { config },
      connections,
    } = state;

    // find index of constant to edit
    const index = config.constants.findIndex((c) => c.id === constant.id);

    // edit constant if index is found
    if (index !== -1) {
      config.constants.splice(index, 1, constant);

      // update mapping rules
      if (shouldUpdateMappingRules) {
        const { mappedConnections } = config.constants[index];

        // traverse connections to find mappings to update
        connections.forEach((c) => {
          // only update connections that use the constant
          if (mappedConnections?.includes(c.id)) {
            const { mapping } = c;

            // find index of mapping in the connection to modify
            const indexOfRuleToModify = mapping.findIndex((map) => map.usedElements.includes(constant.id));

            if (indexOfRuleToModify !== -1) {
              if (constant.type !== mapping[indexOfRuleToModify].rule.constant.type) {
                // remove mapping if type has changed
                mapping.splice(indexOfRuleToModify, 1);
              } else {
                // update mapping with new constant
                mapping[indexOfRuleToModify].rule.sourceFields = [constant.id];
                mapping[indexOfRuleToModify].rule.constant = constant;
              }
            }
          }
        });
      }
    }
  },

  [types.MUTATE_DELETE_CONSTANT](state: FlowsState, { constant }: { constant: Constant }) {
    const {
      flow: { config },
      connections,
    } = state;

    // find index of constant to delete
    const index = config.constants.findIndex((c) => c.id === constant.id);

    if (index !== -1) {
      // extract list of mapped connections before removing constant
      const { mappedConnections } = config.constants[index];

      // delete constant if index is found
      config.constants.splice(index, 1);

      // traverse connections to find mappings to remove
      connections.forEach((c) => {
        // only update connections that use the constant
        if (mappedConnections?.includes(c.id)) {
          const { mapping } = c;

          // find index of mapping in the connection to delete
          const indexOfRuleToModify = mapping.findIndex((map) => map.usedElements.includes(constant.id));

          if (indexOfRuleToModify !== -1) {
            // remove mapping
            mapping.splice(indexOfRuleToModify, 1);
          }
        }
      });
    }
  },

  [types.MUTATE_CREATE_NEW_BUFFER](state: FlowsState, value: SchemaType) {
    state.connectionSource = value;
  },

  [types.MUTATE_ADD_TO_BUFFER](
    state: FlowsState,
    {
      connection,
      schema,
      bufferName,
    }: { connection: Connection; schema: SchemaType; bufferName: string },
  ) {
    if (!connection) return;
    state.connectionSource = undefined;

    const { connections } = state;
    const schemaCopy = $.extend(true, {}, schema);
    const connectionToModify = connections.find((c) => c.id === connection.id);

    // if the connection not exist just return
    if (!connectionToModify) {
      return;
    }

    if (bufferName) {
      schemaCopy.name = bufferName;
    }

    // create the buffer property

    // generate new ID for the buffer
    const bufferId = `${BUFFER_ID_PREFIX}${nanoid.nanoid()}`;

    generateParentChainForSchema(schemaCopy, []);

    // Create Source
    const newSource = [...(schema.parents || []), schema.name];

    const newBuffer: IBuffer = {
      id: bufferId,
      source: newSource,
      value: schemaCopy,
    };

    // Add the new buffer to the state
    state.connections = state.connections.map((c: any) => {
      if (c.id === connection.id) {
        const { buffer } = c;
        buffer[bufferId] = newBuffer;
      }
      return c;
    });
  },

  [types.MUTATE_DELETE_BUFFER](state: FlowsState, payload: any) {
    const { bufferId, connectionId } = payload;

    state.connections = state.connections.map((c: any) => {
      if (c.id === connectionId) {
        const { buffer } = c;
        delete buffer[bufferId];
      }
      return c;
    });
  },

  [types.MUTATE_ADD_FIELD_TO_MAP](
    state: FlowsState,
    { schema, itemToAddTo }: { schema: SchemaType; itemToAddTo: SchemaType },
  ) {
    // Initialize elements array to push schema in
    if (!itemToAddTo.elements) {
      Vue.set(itemToAddTo, 'elements', []);
    }

    // Add field into the map
    itemToAddTo.elements?.push(schema);
  },

  [types.MUTATE_REMOVE_FIELD_FROM_MAP](
    state: FlowsState,
    { brick, port, item }: { brick: BrickInFlow; port: any; item: SchemaType },
  ) {
    const {
      flow: {
        config: { bricks },
      },
      connections,
    } = state;

    editOrDeleteSchemaFromElements(bricks, brick, port, item, 'delete');

    // remove any mapping rule related to this field
    removeMappingForField(connections, item);

    // remove any mapping rule if the connected fields are not available in port's element
    removeMappingIfConnectedFieldsAreNotAvailable(port, connections);
  },

  [types.MUTATE_EDIT_FIELD_IN_MAP](
    state: FlowsState,
    { brick, port, item }: { brick: BrickInFlow; port: any; item: SchemaType },
  ) {
    const {
      flow: {
        config: { bricks },
      },
      connections,
    } = state;

    editOrDeleteSchemaFromElements(bricks, brick, port, item, 'edit');

    // remove any mapping rule related to this field
    removeMappingForField(connections, item);

    // remove any mapping rule if the connected fields are not available in port's element
    removeMappingIfConnectedFieldsAreNotAvailable(port, connections);
  },

  [types.MUTATE_BUFFER_ELEMENT](
    state: FlowsState,
    {
      connection,
      targetSchema,
      isReset = false,
    }: { connection: Connection; targetSchema: SchemaType; isReset: boolean },
  ) {
    const { connections } = state;

    const buffers: IBuffer[] = Object.values(connection.buffer);
    const newBuffer: BufferElement = connection.buffer;

    buffers.map((buffer) => {
      Vue.set(
        newBuffer[buffer.id],
        'value',
        isReset ? resetValidation(buffer.value) : validate(buffer.value, targetSchema, true),
      );

      return buffer;
    });

    const connectionToModify = connections.find((c) => c.id === connection.id);

    if (connectionToModify) {
      Vue.set(connectionToModify, 'buffer', newBuffer);
    }
  },

  [types.MUTATE_UPDATE_BRICK](state: FlowsState, brick: BrickInFlow) {
    const index = state.flow.config.bricks.findIndex((b) => b.instanceId === brick.instanceId);

    if (index !== -1) {
      state.flow.config.bricks.splice(index, 1, brick);
    }
  },

  [types.MUTATE_UPDATE_BRICK_LOADING](state: FlowsState, isLoading: boolean) {
    state.updateBrickLoading = isLoading;
  },
};
