var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-menu',{attrs:{"bottom":"","offset-y":"","nudge-left":"20","transition":"slide-y-transition","close-on-content-click":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();},"mousedown":function($event){$event.stopPropagation();}}},on),[_c('v-icon',[_vm._v(" mdi-menu ")])],1)]}}])},[_c('v-list',{staticClass:"pa-0",attrs:{"dense":"","elevation":"1"}},[_c('v-list-item',{on:{"mousedown":function($event){$event.stopPropagation();},"click":function($event){$event.stopPropagation();_vm.popupInfoDialog = true}}},[_c('v-list-item-icon',{staticClass:"ma-0 pa-0"},[_c('v-tooltip',{attrs:{"left":"","open-delay":400},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v(" mdi-cog ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('flow_setting')))])])],1)],1),_c('v-list-item',{on:{"mousedown":function($event){$event.stopPropagation();},"click":function($event){$event.stopPropagation();_vm.copyDialog = true}}},[_c('v-list-item-icon',{staticClass:"ma-0 pa-00"},[_c('v-tooltip',{attrs:{"left":"","open-delay":400},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v(" mdi-content-copy ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('copy')))])])],1)],1),_c('v-list-item',{on:{"mousedown":function($event){$event.stopPropagation();},"click":function($event){$event.stopPropagation();return _vm.submitExportFlow()}}},[_c('v-list-item-icon',{staticClass:"ma-0 pa-00"},[_c('v-tooltip',{attrs:{"left":"","open-delay":400},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v(" mdi-export-variant ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('export_flow')))])])],1)],1),_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();_vm.deleteDialog = true},"mousedown":function($event){$event.stopPropagation();}}},[_c('v-list-item-icon',{staticClass:"ma-0 pa-0"},[_c('v-tooltip',{attrs:{"left":"","open-delay":400},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.isFlowReadOnly,"icon":""},on:{"mousedown":function($event){$event.stopPropagation();}}},on),[_c('v-icon',{attrs:{"medium":"","color":"error"}},[_vm._v(" mdi-delete ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('delete')))])])],1)],1)],1)],1),_c('DeleteFlow',{attrs:{"dialog":_vm.deleteDialog,"id":("deleteflow-" + _vm.index),"name":_vm.flowName,"flowID":_vm.flowID,"isFlowReadOnly":_vm.isFlowReadOnly},on:{"close":function($event){_vm.deleteDialog = false}}}),_c('CopyFlow',{attrs:{"dialog":_vm.copyDialog,"id":("copyflow-" + _vm.index),"flowID":_vm.flowID},on:{"close":function($event){_vm.copyDialog = false}}}),_c('PopupInfo',{attrs:{"dialog":_vm.popupInfoDialog,"flowID":_vm.flowID,"flowName":_vm.flowName,"flowDescription":_vm.flowDescription,"image":_vm.previewimage,"flowOwner":_vm.flowOwner,"isFlowReadOnly":_vm.isFlowReadOnly},on:{"close":function($event){_vm.popupInfoDialog = false}}}),_c('ExportFlow',{ref:"exportFlow",attrs:{"flowName":_vm.flowName,"flowId":_vm.flowID}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }