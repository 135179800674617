















































import { Action, Getter } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import * as types from '@/store/types';

const namespace: string = 'flow';
@Component
export default class CreateFlow extends Vue {
  @Action(types.CREATE_FLOW, { namespace }) createFlow: any;

  @Getter(types.GET_FLOWS, { namespace }) getFlows: any;

  private newName: string = '';

  async put() {
    this.newName = this.newName.trim();

    if (this.newName === '') {
      return;
    }

    try {
      const { workspaceId } = this.$route.params;
      this.$emit('submited');

      await this.createFlow({ name: this.newName, workspaceId });
    } catch (error) {
      // Toggle flow add OFF
      // To reset variable
      this.$emit('submited');
    }

    this.newName = '';
  }

  close() {
    this.$emit('close');
  }
}
