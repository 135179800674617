var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Toolbar',{attrs:{"title":_vm.workspace.name}}),(_vm.renderComponent)?_c('v-container',[(_vm.getLoading && !_vm.getWorkspaces)?_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"d-flex flex-wrap justify-center",attrs:{"cols":"12"}},_vm._l((3),function(index){return _c('v-skeleton-loader',{key:index,staticClass:"mr-4 mt-4",attrs:{"height":"150","width":"300","type":"card"}})}),1)],1):_c('v-row',{staticClass:"fade-in-left",attrs:{"justify":"center"}},[_c('v-col',{ref:"flowContainer",staticClass:"d-flex flex-wrap justify-center",attrs:{"cols":"12"}},[_vm._l((_vm.getFlowNames),function(flow){return _c('span',{key:flow.id},[_c('v-card',{staticClass:"mr-4 mt-4 d-flex flex-column",attrs:{"outlined":"","max-width":"300","min-width":"300","min-height":"150","max-height":"150","elevation":"1"}},[_c('v-card-title',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"10"}},[_c('Avatar',{attrs:{"size":35,"getUser":{ email: _vm.getFlowOwnerEmail(flow.owner) },"fontSizeClass":'caption'}})],1),_c('v-col',{attrs:{"cols":"2"}},[(!flow.running)?_c('FlowMenu',{attrs:{"flowID":flow.id,"flowName":flow.name,"previewimage":flow.previewimage,"flowDescription":flow.description,"index":flow.id,"flowOwner":flow.owner,"isFlowReadOnly":flow.running}}):_vm._e(),(!_vm.loading)?_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(flow.running)?_c('v-img',_vm._g({attrs:{"src":require("@/assets/images/logo/logo.gif"),"width":"50","height":"35","contain":""}},on)):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('flow_running')))])]):_vm._e()],1)],1)],1),_c('v-card-text',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"body-1"},on),[_vm._v(_vm._s(_vm.truncated(flow.name)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(flow.name))])])],1),_c('v-row',{staticClass:" mt-0",attrs:{"justify":"space-around"}},[_c('v-col',{staticClass:"text-left",attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"bottom":"","open-delay":400},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","depressed":""},on:{"mousedown":function($event){$event.stopPropagation();},"click":function($event){$event.stopPropagation();return _vm.routeToFlows(flow)}}},on),[_c('v-icon',{attrs:{"large":"","color":"primary"}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('edit')))])])],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"bottom":"","open-delay":400},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","disabled":_vm.loading,"depressed":""},on:{"click":function($event){$event.stopPropagation();return _vm.changeFlowState(flow)},"mousedown":function($event){$event.stopPropagation();}}},on),[_c('v-icon',{attrs:{"large":"","color":flow.running ? 'warning' : 'primary'}},[_vm._v(_vm._s(flow.running ? 'mdi-stop' : 'mdi-play'))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(flow.running === true ? _vm.$t('stop_flow') : _vm.$t('start_flow')))])])],1)],1)],1)],1)],1)}),_c('NewFlow',{key:"addButton",on:{"toggle-flow-added":function($event){_vm.isFlowAdded = !_vm.isFlowAdded}}})],2)],1),_c('ParameterErrors',{attrs:{"dialog":_vm.dialogParameterError,"items":_vm.invalidBrickParams},on:{"close-dialog":function($event){_vm.dialogParameterError = false;
        _vm.invalidBrickParams = [];}}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }