import { Constant, SchemaType } from '@/scripts/shareModels/schema';

const typesMatch: boolean = true;
const typesDontMatch: boolean = false;
const typesWithPrecision: string[] = ['int', 'float'];

/**
 * check that the precision of the source type
 * is lower than the one of the target type
 * e.g. int16 < int32
 * @param source string: the source type
 * @param target : string the target type
 */
export const sourcePrecisionLTtargetPrecision = (source: string, target: string) => {
  for (const typeName of typesWithPrecision) {
    if (source.includes(typeName) && target.includes(typeName)) {
      return Number(source.replace(typeName, '')) < Number(target.replace(typeName, ''));
    }
  }
  return typesDontMatch;
};

/**
 * check if types match
 * types match
 * - they are identical,
 * - the target is a variant
 * - the target type is a list of arrays and the source type is
 *    contained in the list
 * - the source type is a map, target type an object (and vice versa)
 * - the source type is a record and the target type a list
 */
export const matchTypes = (source: SchemaType, target: SchemaType) => {
  if (
    source.type === target.type
    || target.type === 'variant'
    || (source.type === 'object' && target.type === 'map')
    || (source.type === 'map'
      && target.type
        === 'object')
    || /* actually this may lead to omitted keys and should be decomissioned once a better treatmenet of map-key mapping is available */ (source.type === 'record' && target.type === 'list')
  ) {
    return typesMatch;
  }
  if (Array.isArray(target.type)) {
    return target.type.includes(source.type);
  }

  return sourcePrecisionLTtargetPrecision(source.type, target.type);
};

/**
 * Validate that the schemata are matching
 * by evaluating its type and the types of the elements
 * @param targetSchema - The target schema for validation
 * @param sourceSchema - The source schema for validation
 */
export const validate = (
  sourceSchema: SchemaType,
  targetSchema: SchemaType,
  isTarget: boolean = false,
) => {
  const schema: SchemaType = isTarget ? sourceSchema : targetSchema;

  if (!matchTypes(sourceSchema, targetSchema)) {
    schema.hideCheckBox = true;
  }

  if (schema.elements && schema.elements.length > 0) {
    if (isTarget) {
      schema.elements?.map((element) => validate(element, targetSchema, true));
    } else {
      schema.elements?.map((element) => validate(sourceSchema, element));
    }
  }

  return schema;
};

/**
 * Validate the constant against a schema type
 *
 * @param constant The constant to validate
 * @param targetSchema The schema to validate the constant
 * against
 */
export const validateConstant = (constant: Constant, targetSchema: SchemaType) => {
  const constantToValidate = constant;

  if (!matchTypes(constant, targetSchema)) {
    constantToValidate.hideCheckBox = true;
  }

  return constant;
};

/**
 * Resets the hideCheckBox element of each
 * type and subtype after and thereby
 * resets the validation
 *
 * @param schema - The schema for which validations are to be reset
 */
export const resetValidation = (schema: SchemaType) => {
  const clearedSchema: SchemaType = schema;
  clearedSchema.hideCheckBox = false;
  if (schema.elements && schema.elements.length > 0) {
    clearedSchema.elements?.map((element) => resetValidation(element));
  }
  return clearedSchema;
};

/**
 * Resets the hideCheckBox element of each
 * constant that was disabled during validation
 *
 * @param constant - The constant for which validations are to be reset
 */
export const resetConstantValidation = (constant: Constant) => {
  const constantToReset = constant;

  constantToReset.hideCheckBox = false;

  return constantToReset;
};
