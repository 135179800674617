























import { Component, Watch, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import * as types from '@/store/types';

const namespace: string = 'error';

@Component
export default class Toast extends Vue {
  @Getter(types.GET_ERROR, { namespace }) getError: any;

  @Getter(types.GET_ERROR_LEVEL, { namespace }) getErrorLevel: any;

  @Getter(types.GET_ERROR_SERVICE, { namespace }) getErrorService: any;

  @Action(types.SET_ERROR, { namespace }) setError: any;

  private snackbar: boolean = false;

  private color: string = '#fff';

  private textColor: string = '#000';

  private timeout: number = 5000;

  private countError: number = 0;

  @Watch('getError')
  onErrorChanged(value: string, oldValue: string) {
    if (value) {
      this.level();
      this.snackbar = true;
      setTimeout(() => {
        this.snackbar = false;
        this.setError('');
      }, this.timeout);
    }
  }

  get handleError() {
    switch (this.getError) {
      case "Cannot read property 'status' of undefined":
        return 'network_error';
      default:
        return this.getError;
    }
  }

  level() {
    switch (this.getErrorLevel) {
      case 0:
        this.color = 'green';
        this.textColor = 'white';
        break;
      case 1:
        this.color = 'yellow';
        this.textColor = 'black';
        break;
      default:
        this.color = 'red';
        this.textColor = 'white';
    }
  }

  closeSnackbar() {
    this.snackbar = false;
    this.setError('');
  }
}
