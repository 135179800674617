/*
 Copyright 2019-present wobe-systems GmbH

 Licensed under the Apache License, Version 2.0 (the 'License');
 you may not use this file except in compliance with the License.
 You may obtain a copy of the License at

     http://www.apache.org/licenses/LICENSE-2.0

 Unless required by applicable law or agreed to in writing, software
 distributed under the License is distributed on an 'AS IS' BASIS,
 WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 See the License for the specific language governing permissions and
 limitations under the License.
*/
/* eslint-disable max-len */
import { MutationTree } from 'vuex';
import * as types from '@/store/types';
import { API, APIState, Endpoint } from './models';

export const mutations: MutationTree<APIState> = {
  [types.LOADING](state, loading: APIState['loading']) {
    state.loading = loading;
  },

  [types.MUTATE_APIS](state, apis: API[]) {
    // UI can not accept the enpoints as null, so incase the endpoint is nul
    // we set the empty array
    const newApis = apis.map((a:API) => {
      const api = a;
      if (!api.endpoints) {
        api.endpoints = [];
      }
      return api;
    });
    state.apis = newApis || [];
  },

  [types.MUTATE_DELETED_API](state, id: string) {
    const { apis } = state;

    const index = apis.findIndex((a) => a.id === id);

    if (index !== -1) {
      apis.splice(index, 1);
    }
  },

  [types.MUTATE_ADD_API](state, api: API) {
    state.apis = [...state.apis, api];
  },

  [types.MUTATE_EDIT_API](state, api: API) {
    const index = state.apis.findIndex((a) => {
      if (!a.id || !api.id) return false;
      return a.id === api.id;
    });
    if (index !== -1) {
      state.apis.splice(index, 1, api);
    }
  },

  [types.MUTATE_ADDED_ENDPOINT](state,
    {
      apiId,
      endpoint,
    }: { apiId: string, endpoint: Endpoint}) {
    const { apis } = state;

    const api = apis.find((a) => a.id === apiId);

    if (api) {
      api.endpoints?.push(endpoint);
    }
  },

  [types.MUTATE_EDITED_ENDPOINT](state,
    {
      apiId,
      endpoint,
    }: { apiId: string, endpoint: Endpoint}) {
    const { apis } = state;

    const api = apis.find((a) => a.id === apiId);

    if (api && api.endpoints) {
      const endpointIndex = api.endpoints.findIndex((e) => e.id === endpoint.id && e.apiId === apiId);

      if (endpointIndex !== -1) {
        api.endpoints.splice(endpointIndex, 1, endpoint);
      }
    }
  },

  [types.MUTATE_DELETED_ENDPOINT](state, { apiId, endpointId }) {
    const { apis } = state;

    const api = apis.find((a) => a.id === apiId);

    if (api && api.endpoints) {
      const endpointIndex = api.endpoints.findIndex((e) => e.id === endpointId && e.apiId === apiId);

      if (endpointIndex !== -1) {
        api.endpoints?.splice(endpointIndex, 1);
      }
    }
  },

};
