



































































import { Getter } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import * as types from '@/store/types';
import CreateFlow from './CreateFlow.vue';
import ImportFlow from './ImportFlow.vue';

const namespace: string = 'flow';

@Component({
  components: {
    CreateFlow,
    ImportFlow,
  },
})
export default class Index extends Vue {
  @Getter(types.GET_FLOWS, { namespace }) getFlows: any;

  private dialog: boolean = false;

  file: string = '';

  submit() {
    // Toggle flow add ON
    // For ADD animation
    this.$emit('toggle-flow-added');

    this.dialog = false;
  }
}
